import { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Link, CircularProgress, Box } from '@mui/material';

import { FormLabel } from 'components/generic/form';

import Form from 'containers/form';

import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import authService from '../../../../services/api/auth.service';

import { useSnackbar } from 'notistack';
import axios, { AxiosError } from 'axios';
import useStore from 'store';
import ControlledTextInput from 'containers/form/ControlledTextInput';
import ControlledSubmit from 'containers/form/ControlledSubmit';
import useValidateToken from 'services/useValidateToken';
import ErrorContent from 'containers/views/ErrorContent';

const Flex = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface IFormInputs {
  password1: string;
  password2: string;
}

// TODO: show form only of token is valid - validate on mount
// add token invalid message
// add redirect to login after successful password change

const ChangePassword = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();

  const [appIsLoading, setAppIsLoading] = useStore((state) => [
    state.appIsLoading,
    state.setAppLoading,
  ]);

  const navigate = useNavigate();

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.setAttribute('onpaste', 'return false;');
  }, [ref]);

  const { token } = useParams();
  const { tokenValid, isValidating } = useValidateToken(token);

  /*useEffect(() => {
    if (tokenValid === false) {
      navigate('/error', {
        state: {
          message: 'platnosť adresy pre zmenu hesla vypršala.',
          subMessage: 'Požiadajte znovu o zmenu hesla.',
        },
      });
    }
  }, [tokenValid]);*/

  const onSubmit = async (data: IFormInputs) => {
    try {
      const { password1, password2 } = data;

      if (password1 !== password2) {
        throw new Error('Heslá sa nezhodujú.');
      }

      setAppIsLoading(true);
      const res = await authService.changePassword(password1, token as string);

      enqueueSnackbar(`Heslo bolo úspešne zmenené. Môžete sa prihlásiť.`, {
        variant: 'success',
      });

      setTimeout(() => {
        navigate('/');
      }, 1500);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const err = e as AxiosError;
        if (err.response?.status === 401) {
          enqueueSnackbar('Neplatný email alebo heslo', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(err.message, {
            variant: 'error',
          });

          setTimeout(() => {
            navigate('/');
          }, 1500);
        }
      }
      enqueueSnackbar((e as Error).message, {
        variant: 'error',
      });
    } finally {
      setAppIsLoading(false);
    }
  };

  if (isValidating)
    return (
      <Box display="flex" width="100%" justifyContent="center">
        <CircularProgress color="primary" size={64} />
      </Box>
    );

  return tokenValid ? (
    <Form<IFormInputs>
      onSubmit={onSubmit}
      autoComplete="new-password"
      padded={true}
    >
      <FormLabel variant="h2">Zmeniť heslo</FormLabel>
      <ControlledTextInput
        name="password1"
        rule="password"
        label="Nové heslo"
        type="password"
      />
      <ControlledTextInput
        name="password2"
        rule="password"
        label="Zopakujte nové heslo"
        type="password"
        inputRef={ref}
      />
      <ControlledSubmit label="Zmeniť heslo" />
      <Flex>
        <Typography color="textSecondary" variant="body1">
          <Link component={RouterLink} to="/">
            &larr; Späť na Prihlásenie
          </Link>
        </Typography>
      </Flex>
    </Form>
  ) : (
    <Box display="flex" justifyContent="center" width="100%">
      <ErrorContent
        message="platnosť požiadavky pre zmenu hesla vypršala."
        subMessage="Požiadajte znovu o zmenu hesla."
        hideImage
      >
        <Typography color="textSecondary" variant="body1" paddingTop={16}>
          <Link component={RouterLink} to="/">
            &larr; Späť na Prihlásenie
          </Link>
        </Typography>
      </ErrorContent>
    </Box>
  );
};

export default ChangePassword;
