import { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Link, InputAdornment, IconButton } from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { FormLabel } from 'components/generic/form';

import Form from 'containers/form';

import { Link as RouterLink } from 'react-router-dom';

import authService from '../../../../services/api/auth.service';

import { GateCtx } from '../../Gate';
import useStore from 'store';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import ControlledTextInput from 'containers/form/ControlledTextInput';
import ControlledCheckbox from 'containers/form/ControlledCheckbox';
import ControlledSubmit from 'containers/form/ControlledSubmit';

const Flex = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface IFormInputs {
  email: string;
  password: string;
  remember: boolean;
}

const SignIn = () => {
  const { enqueueSnackbar } = useSnackbar();

  const gateCtx = useContext(GateCtx);

  const setUser = useStore((state) => state.setUser);

  const [appIsLoading, setAppIsLoading] = useStore((state) => [
    state.appIsLoading,
    state.setAppLoading,
  ]);

  const [showPassword, setShowPassword] = useState(false);
  const [t, setT] = useState<ReturnType<typeof setTimeout> | null>();

  useEffect(() => {
    return () => {
      t && clearTimeout(t);
    };
  }, [t]);

  const onSubmit = async (data: IFormInputs) => {
    try {
      setAppIsLoading(true);
      const res = await authService.login(
        data.email,
        data.password,
        data.remember,
      );

      setUser(res);

      enqueueSnackbar(`Vitaj ${res.nickName || res.name} !`, {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        variant: 'default',
      });
    } catch (e) {
      const err = e as AxiosError;
      if (err.response?.status === 401) {
        enqueueSnackbar('Neplatný email alebo heslo', {
          variant: 'error',
        });
      }
    } finally {
      setAppIsLoading(false);
    }

    gateCtx.setEmail?.(data.email);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    if (!t) {
      const timeout = setTimeout(() => {
        setShowPassword(false);
        setT(null);
      }, 3000);
      setT(timeout);
    }
  };

  const handleMouseDownPassword = (e: any) => {
    e.preventDefault();
  };

  return (
    <Form<IFormInputs>
      onSubmit={onSubmit}
      autoComplete="new-password"
      padded={true}
    >
      <FormLabel variant="h2" style={{ color: 'pink' }}>
        Prihlásenie
      </FormLabel>
      <ControlledTextInput label="Emailová adresa" name="email" />
      <ControlledTextInput
        label="Heslo"
        name="password"
        type={showPassword ? 'test' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <ControlledSubmit label="Prihlásiť" isLoading={appIsLoading} />
      <Flex>
        <Typography color="textSecondary" variant="body1">
          <Link component={RouterLink} to="/lost-password">
            Zabudnuté heslo
          </Link>
        </Typography>
        <ControlledCheckbox name="remember" label="Zostať prihlásený" />
      </Flex>
    </Form>
  );
};

export default SignIn;
