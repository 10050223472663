import { styled } from '@mui/material/styles';

import { GetManyRes } from '../../../../../types/GetManyRes.d';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Typography,
  Grid,
  Box,
} from '@mui/material';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import mockData from './data';

import { useQuery } from 'react-query';
import { get } from 'services/api/queryClient';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const Root = styled(Card)`
  height: 100%;
`;

const Content = styled(CardContent)`
  padding: 0;
`;

const Actions = styled(CardActions)`
  justify-content: flex-end;
`;

const MetaInfoTypograpy = styled(Typography)`
  display: block;
  width: 100%;
`;

export function NewOrders(props: any) {
  //const [orders] = useState(mockData);

  const navigate = useNavigate();

  const { isFetching, error, data } = useQuery<GetManyRes>(
    ['offer', { params: { status: 'NEW' } }],
    get
  );

  return (
    <Root>
      <CardHeader
        subheader={`počet: ${data && data.results ? data.results.length : 0}`}
        title="Nové objednávky"
      />
      <Content>
        <List>
          {data &&
            data.results.slice(0, 5).map((d: any, i: number) => {
              return (
                <ListItem
                  divider={i < 5 - 1}
                  key={d.id}
                  sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
                >
                  <ListItemText
                    primary={`${d.message?.slice(0, 250)} ${
                      d.message?.length > 250 ? '...' : ''
                    }`}
                  />
                  <Box pb={2} />
                  <Grid container>
                    <Grid item xs={6}>
                      <MetaInfoTypograpy variant="caption" align="left">
                        {d.email}
                      </MetaInfoTypograpy>
                    </Grid>
                    <Grid item xs={6}>
                      <MetaInfoTypograpy
                        variant="caption"
                        align="right"
                      >{`Pridaný ${moment(
                        d.createdAt
                      ).fromNow()}`}</MetaInfoTypograpy>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
        </List>
      </Content>
      <Divider />
      <Actions>
        <Button
          color="primary"
          size="small"
          variant="text"
          onClick={() => navigate('/orders')}
        >
          Zobraziť všetky <ArrowRightIcon />
        </Button>
      </Actions>
    </Root>
  );
}

export default NewOrders;
