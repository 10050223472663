import { useState, createContext } from 'react';
import { styled } from '@mui/material/styles';

import { Grid, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import TripitoClassicBg from '../../../assets/tripito-classic.jpg';

import { useLocation, Outlet, Link as RouterLink } from 'react-router-dom';

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: 'calc(100vh - 64px)',
}));

const MainGrid = styled(Grid)`
  && {
    height: 100%;
  }
`;

// left side
const QuoteGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

// https://github.com/mui-org/material-ui/issues/29207
const QuoteDiv = styled('div', {
  // indicate that this prop name should be used to conditionally
  // style the component only and should not be spread to the DOM element.
  shouldForwardProp: (propName) => propName !== 'backgroundImage',
})<{ backgroundImage?: string }>(({ theme, backgroundImage }) => ({
  backgroundColor: theme.palette.neutral,
  height: '100%',
  display: 'flex',
  paddingTop: '100px',
  justifyContent: 'center',
  alignItems: 'flex-start',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}));

const QuoteInnerDiv = styled('div')`
  text-align: center;
  flex-basis: 600px;
`;

const QuoteText = styled(Typography)(({ theme }) => ({
  color: theme.palette.black,
  fontWeight: 300,
}));

// right side
const ContentGrid = styled(Grid)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentHeaderDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(5),
  paddingBototm: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const ContentDiv = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const ContentBodyDiv = styled(`div`)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'center',
  },
}));

//////////////// Gate Context
interface IGateContext {
  email: null | string;
  setEmail?: React.Dispatch<any>;
}

const defaultGateContext = {
  email: null,
};

export const GateCtx = createContext<IGateContext>(defaultGateContext);
////////////////

export function Gate(props: any) {
  const { pathname } = useLocation();

  const [email, setEmail] = useState(null);

  return (
    <GateCtx.Provider value={{ email, setEmail }}>
      <Root>
        <MainGrid container>
          {/* left side */}
          <QuoteGrid item lg={5}>
            <QuoteDiv backgroundImage={TripitoClassicBg}>
              <QuoteInnerDiv>
                <QuoteText variant="h1">Tripito CRM</QuoteText>
              </QuoteInnerDiv>
            </QuoteDiv>
          </QuoteGrid>
          {/* right side */}
          <ContentGrid item lg={7} xs={12}>
            <ContentDiv>
              <ContentHeaderDiv>
                {pathname !== '/' && !pathname.includes('change-password') ? (
                  <IconButton component={RouterLink} to="/">
                    <ArrowBackIcon />
                  </IconButton>
                ) : (
                  ''
                )}
              </ContentHeaderDiv>
              <ContentBodyDiv>
                <Outlet />
              </ContentBodyDiv>
            </ContentDiv>
          </ContentGrid>
        </MainGrid>
      </Root>
    </GateCtx.Provider>
  );
}

export default Gate;
