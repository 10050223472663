import { styled } from '@mui/material/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar as MuiAvatar,
  Theme,
  CircularProgress,
} from '@mui/material';

import HailIcon from '@mui/icons-material/HailOutlined';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import { useQuery } from 'react-query';
import { get } from 'services/api/queryClient';

const Root = styled(Card)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
})) as typeof Grid;

const Title = styled(Typography)`
  font-weight: 700;
`;

const Avatar = styled(MuiAvatar)(({ theme }) => ({
  backgroundColor: theme.palette.white,
  color: theme.palette.primary.main,
  height: 56,
  width: 56,
}));

const icon = (theme: Theme) => ({
  height: 32,
  width: 32,
});

export function TotalDemands(props: any) {
  const { isFetching, data, error } = useQuery(['demand/count'], get, {
    retry: false,
  });

  return (
    <Root>
      <CardContent>
        <Grid container sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Title color="white" gutterBottom variant="body2">
              Počet dopytov
            </Title>
            <Typography color="white" variant="h3">
              {!data && isFetching && (
                <CircularProgress size={32} sx={{ color: 'white' }} />
              )}
              {!data && error && <SentimentVeryDissatisfiedIcon sx={icon} />}
              {data && data.count}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar>
              <HailIcon sx={icon} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Root>
  );
}

export default TotalDemands;
